<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 1250px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">УИК</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'uik' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-1">
              <label class="form-label">№</label>
              <input type="number" :class="{ 'is-invalid': formError.num }"  min="0" step="1" class="form-control" v-model="form.num" />
              <fieldErrors v-bind:errors="formError.num"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Управление</label>
              <select :class="{ 'is-invalid': formError.tu }" class="form-select" v-model="form.tu">
                <option v-for="tu in form.tu_choices" :value="tu.id" :key="tu.id">
                  {{ tu.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.tu"></fieldErrors>
            </div>

            <div class="col-lg-8">
              <label class="form-label">Центр голосования (адрес/телефон)</label>
              <input type="text" class="form-control" placeholder="" :class="{ 'is-invalid': formError.address }" v-model="form.address" />
              <fieldErrors v-bind:errors="formError.address"></fieldErrors>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-2">
              <label class="form-label">ФИО председателя</label>
              <input type="text" :class="{ 'is-invalid': formError.chief_fio }" class="form-control" v-model="form.chief_fio" />
              <fieldErrors v-bind:errors="formError.chief_fio"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Телефон председателя</label>
              <input type="text" :class="{ 'is-invalid': formError.chief_phone }" class="form-control" v-model="form.chief_phone" />
              <fieldErrors v-bind:errors="formError.chief_phone"></fieldErrors>
            </div>
            <div class="col-lg-7">
              <label class="form-label">Описание границ (улицы)</label>
              <input type="text" :class="{ 'is-invalid': formError.borders }" class="form-control" v-model="form.borders" />
              <fieldErrors v-bind:errors="formError.borders"></fieldErrors>
            </div>
            <div class="col-lg-1">
              <label class="form-label">№ округа</label>
              <input type="text" :class="{ 'is-invalid': formError.district }" class="form-control" v-model="form.district" />
              <fieldErrors v-bind:errors="formError.district"></fieldErrors>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-2">
              <label class="form-label">Всего избирателей</label>
              <input type="number" :class="{ 'is-invalid': formError.voters }"  min="0" step="1" class="form-control" v-model="form.voters" />
              <fieldErrors v-bind:errors="formError.voters"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">60+</label>
              <input type="number" :class="{ 'is-invalid': formError.sixty_plus }"  min="0" step="1" class="form-control" v-model="form.sixty_plus" />
              <fieldErrors v-bind:errors="formError.sixty_plus"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">80+</label>
              <input type="number" :class="{ 'is-invalid': formError.eighty_plus }"  min="0" step="1" class="form-control" v-model="form.eighty_plus" />
              <fieldErrors v-bind:errors="formError.eighty_plus"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Многодетные</label>
              <input type="number" :class="{ 'is-invalid': formError.large_families }"  min="0" step="1" class="form-control" v-model="form.large_families" />
              <fieldErrors v-bind:errors="formError.large_families"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Инвалиды</label>
              <input type="number" :class="{ 'is-invalid': formError.disableds }"  min="0" step="1" class="form-control" v-model="form.disableds" />
              <fieldErrors v-bind:errors="formError.disableds"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Впервые голосующие</label>
              <input type="number" :class="{ 'is-invalid': formError.first_time }"  min="0" step="1" class="form-control" v-model="form.first_time" />
              <fieldErrors v-bind:errors="formError.first_time"></fieldErrors>
            </div>
          </div>



          <div class="mb-3">
            <div class="row">
              <div class="col-lg-12">
                <label class="form-label">
                  Дома
                  <a class="btn btn-sm icon-btn" @click="addVotercounts()"><i class="fa fa-plus"></i> добавить</a>
                </label>
              </div>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th style="width:200px">Улица</th>
                  <th>Дома</th>
                  <th style="width:150px" class="text-end">Кол-во домов</th>
                  <th style="width:100px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="uikhome in new_homes_filter(form.homes)" :key="uikhome.loop_index">
                  <td style="white-space: nowrap;vertical-align: middle;">
                    <select
                      class="form-select"
                      v-model="uikhome.street"
                      style="width: 200px"
                    >
                      <option v-for="street in form.streets" :value="street.id" :key="street.id">
                        {{ street.name }}
                      </option>
                    </select>
                  </td>
                  <td style="white-space: nowrap;vertical-align: middle;" class="text-end">
                    <input type="text" class="form-control" v-model="uikhome.homes" />
                  </td>
                  <td style="white-space: nowrap;vertical-align: middle;">
                    <input type="text" class="form-control" v-model="uikhome.homes_count"  min="0" step="1"/>
                  </td>
                  <td>
                    <button
                    class="btn"
                    type="button"
                    @click="removeNewHomes(uikhome.loop_index)"
                    style="margin-left:1em"
                    title="Редактировать"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  </td>
                </tr>

                <tr v-for="(uikhome, index) in homes_filter(form.homes)" :key="uikhome.id">
                  <td style="white-space: nowrap;vertical-align: middle;">
                    {{ uikhome.street_name }}
                  </td>
                  <td style="white-space: nowrap;vertical-align: middle;">
                    <input type="text" class="form-control" v-model="uikhome.homes" />
                  </td>
                  <td class="td-input-numbers">
                    <input type="number" class="form-control" v-model="uikhome.homes_count" min="0" step="1" />
                  </td>
                  <td>
                    <button
                    class="btn"
                    type="button"
                    @click="removeHomes(index)"
                    style="margin-left:1em"
                    title="Удалить"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  </td>
                </tr>


              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto">
            <a class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>

          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'uik' }"> Закрыть </router-link>
          </p>
          <p @click="save()">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'uik' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Form",

  components: {
    fieldErrors,
  },

  data: function () {
    return {
      form: {},
      formError: {},
      home_choices: {},
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source)
    if (response.ok) {
      this.form = await response.json()
    } else {
      this.$router.push({ name: "uik" })
      this.$emit("close")
    }
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("save");
      } else {
        this.formError = await response.json()
      }
    },
    addVotercounts() {
      this.form.homes.push({
        "voters": 0,
        "sixty_plus": 0,
        "eighty_plus": 0,
        "large_families": 0,
        "disableds": 0,
        "first_time": 0,
        "total": 0,
        "home_choices": [],
        "loop_index": Math.floor(Math.random()*1000),
      });
    },
    removeHomes(index) {
      if(confirm("Удалить?")) this.form.homes.splice(index, 1)
    },
    removeNewHomes(index) {
      this.form.homes = this.form.homes.filter(home => home.loop_index != index)
    },    
    updateHomeChoices(uikhome){
      let street = uikhome.street
      if(street){
        fetch(`/tu/street/${street}/houses/`)
          .then((response) => response.json())
          .then((data) => {
            uikhome.home_choices = data;
          })
      }
    },
    new_homes_filter(homes){
      if(homes){
        return homes.filter(home => !home.id)
      } else return []
    },
    homes_filter(homes){
      if(homes){
        return homes.filter(home => home.id)
      } else return []
    },
    edit_mode(index){
      this.form.homes[index].edit_mode = !this.form.homes[index].edit_mode
    },
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>
<style>
  .td-input-numbers {width: 80px;white-space: nowrap;vertical-align: middle;text-align: right;}
  .td-input-numbers input {text-align: right;padding-right: 2em}
</style>