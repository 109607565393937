<template>
  <div class="flex-fill d-flex flex-column justify-content-center py-4">
    <div class="container-tight py-6">
      <form class="card card-md" v-on:submit.prevent="">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Восстановление пароля</h2>
          <div class="mb-3">
            <label class="form-label">Email адрес</label>
            <input type="email" :class="{ 'is-invalid': formError.email }" class="form-control" placeholder="Enter email" v-model.trim="email" />
            <fieldErrors v-bind:errors="formError.email"></fieldErrors>
          </div>
          <input type="hidden" :class="{ 'is-invalid': formError.non_field_errors }" />
          <fieldErrors v-bind:errors="formError.non_field_errors"></fieldErrors>
          <div class="form-footer">
            <button v-if="!seccessMessage" type="button" v-on:click="recoveryPass" class="btn btn-primary w-100">Восстановить пароль</button>
            <p v-if="seccessMessage" class="text-success">{{ seccessMessage }}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "RecoveryPass",
  components: {
    fieldErrors,
  },
  data: () => ({
    email: "",
    formError: Object,
    seccessMessage: "",
  }),
  methods: {
    async recoveryPass() {
      const response = await fetch("/auth/create-login-token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
        }),
      });
      if (response.ok) {
        this.formError = {};
        this.seccessMessage = "Письмо с инструкцией отправлено на Ваш Email.";
      } else {
        this.formError = await response.json();
      }
    },
  },
};
</script>

