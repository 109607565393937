<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="white-space:nowrap;margin-right: 1em;">Паспорт улицы</h3>
      <div class="card-actions">
          <div class="form-group">
              <select class="form-select" @change="showReport($event)">
                <option value="0">&raquo; Выберите улицу</option>
                <option v-for="street in streets" :value="street.id" :key="street.id">
                  {{ street.name }}
                </option>
              </select>
          </div>
      </div>

    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter table-hover text-nowrap datatable">
        <tbody>
          <tr>
            <td>Километраж</td>
            <td class="text-center">
              <div v-for="surface in report.length" :key="surface.id">
                {{ surface.surface__name }}: {{ surface.length }} км
              </div>
            </td>
          </tr>
          <tr>
            <td>Домовладений</td>
            <td class="text-center"><a href="#">{{ report.homes }}</a></td>
          </tr>
          <tr>
            <td>Жителей</td>
            <td class="text-center">{{ report.population }}</td>
          </tr>
          <tr>
            <td>Колонок</td>
            <td class="text-center"><a href="#">{{ report.pump }}</a></td>
          </tr>
          <tr>
            <td>Пожарных гидрантов</td>
            <td class="text-center"><a href="#">{{ report.fire_hydrant }}</a></td>
          </tr>
          <tr>
            <td>Мусорных контейнеров</td>
            <td class="text-center"><a href="#">{{ report.container }}</a></td>
          </tr>
          <tr>
            <td>Мусорных бункеров</td>
            <td class="text-center"><a href="#">{{ report.bunker }}</a></td>
          </tr>
          <tr>
            <td>Осветительных приборов</td>
            <td class="text-center"><a href="#">{{ report.lighting }}</a></td>
          </tr>
          <tr>
            <td>Детских площадок</td>
            <td class="text-center"><a href="#">{{ report.childrens_playground }}</a></td>
          </tr>
          <tr>
            <td>Председатель уличкома</td>
            <td class="text-center">              
              <div v-for="ulichkom in report.ulichkoms" :key="ulichkom.id">
                <router-link :to="{ name: 'ulichkoms', params: { id: ulichkom.id } }">{{ ulichkom.ini }}</router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>

export default {
  data: function () {
    return {
      report: {},
      streets: [],
    };
  },
  methods: {
    getStreets() {
      var tu = this.$route.params.tu;
      fetch("/tu/street/search-list/?tu=" + tu)
        .then((response) => response.json())
        .then((data) => {
          this.streets = data
        });
    },
    showReport:function(event){
      let street_id = event.target.value
      if(street_id && street_id>0){
        fetch("/tu/report/pasport/street/" + street_id + "/")
          .then((response) => response.json())
          .then((data) => {
            this.report = data
          });
      } else {
        this.report = {}
      }
    }
  },
  mounted() {
    this.getStreets();
  },

}

</script>
