<template>
  <ul class="navbar-nav">
    <li class="nav-item" v-bind:class="{ active: $route.name == 'streets' }">
      <router-link :to="{ name: 'streets' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="21" y1="17" x2="3" y2="17" />
            <path d="M6 10l-3 -3l3 -3" />
            <line x1="3" y1="7" x2="21" y2="7" />
            <path d="M18 20l3 -3l-3 -3" />
          </svg>
        </span>
        <span class="nav-link-title"> Улицы</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'houses' }">
      <router-link :to="{ name: 'houses' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="5 12 3 12 12 3 21 12 19 12" />
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
          </svg>
        </span>
        <span class="nav-link-title"> Дома </span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'ulichkoms' }">
      <router-link :to="{ name: 'ulichkoms' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block"
          ><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="7" r="4" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
        </span>
        <span class="nav-link-title"> Уличкомы </span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'uik' }">
      <router-link :to="{ name: 'uik' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block"
          ><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
             <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
             <path d="M7 12l5 5l10 -10"></path>
             <path d="M2 12l5 5m5 -5l5 -5"></path>
          </svg>
        </span>
        <span class="nav-link-title"> УИК </span>
      </router-link>
    </li>


    <li class="nav-item" v-bind:class="{ active: $route.name == 'reports' }">
      <router-link :to="{ name: 'reports' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="9" y1="17" x2="9" y2="12" /><line x1="12" y1="17" x2="12" y2="16" /><line x1="15" y1="17" x2="15" y2="14" /></svg>
        </span>
        <span class="nav-link-title"> Отчёты </span>
      </router-link>
    </li>



  </ul>
</template>




<script>
export default {
  name: "Topmenu",
  props: {},
};
</script>