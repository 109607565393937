<template>
  <div class="card" v-on:keyup.esc="showModal = false">
    <div class="card-header">
      <h3 class="card-title">Дома</h3>
    </div>
    <ul class="nav nav-tabs" data-bs-toggle="tabs">
      <li class="nav-item">
        <a class="nav-link" @click="show_table()">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-list"></i>
          </span>
          <span class="nav-link-title"> Список </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" @click="show_map()">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-map-marker"></i>
          </span>
          <span class="nav-link-title"> Карта </span>
        </a>
      </li>
      <li class="nav-item ms-auto right-btns">
        <p
          class="btn btn-primary"
          @click="
            showModal = true;
            modalSource = '/tu/home/new/';
          "
        >
          <i class="fa fa-plus"></i> Добавить
        </p>
      </li>
    </ul>

    <div class="ratio ratio-16x9">
      <div id="map" class="map"></div>
    </div>
    <Form v-if="showModal" @close="hideModalForm" @save="saveModalForm" :source="modalSource"></Form>
  </div>

</template>


<script>
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Form from "./Form.vue";

export default {
  data: function () {
    return {
      map: Object,
      markers_layer: Object,
      marker_style_default: new Style({
        image: new Icon(({
          anchor: [0.5, 1],
          src: "https://cdn.mapmarker.io/api/v1/font-awesome/v5/pin?icon=fa-home&size=40&hoffset=0&voffset=-1",
        }))
      }),
      marker_style_hovered: new Style({
        image: new Icon(({
          anchor: [0.5, 1],
          src: "https://cdn.mapmarker.io/api/v1/font-awesome/v5/pin?icon=fa-home&size=50&hoffset=0&voffset=-1",
        }))
      }),
      showModal: false,
      modalSource: "",
    };
  },
  props: {
    rows: Array,
    paginator: Object,
  },
  components: {
    Form,
  },
  mounted() {
    this.create_map();
    this.set_markers();
  },
  watch: { 
    rows: function() {
      this.set_markers();
    }
  },
  methods: {
    create_map() {
      let
        map = new Map({
          target: 'map',
          layers: [
            new TileLayer({
              source: new OSM()
            })
          ]
        }),
        vectorSource = new VectorSource({}),
        selected = null,
        marker_style_default = this.marker_style_default,
        marker_style_hovered = this.marker_style_hovered;

      this.markers_layer = new VectorLayer({source: vectorSource})
      map.addLayer(this.markers_layer)

      map.on('pointermove', function (e) {
        if (selected !== null) {
          selected.setStyle(marker_style_default)
          selected = null
          map.getViewport().style.cursor = ''
        }

        map.forEachFeatureAtPixel(e.pixel, function (feature) {
          selected = feature
          feature.setStyle(marker_style_hovered)
          map.getViewport().style.cursor = 'pointer'
          return true
        });
      });
      let showModalForm = this.showModalForm;

      map.on("click", function(e) {
          let clicked_feature = null
          map.forEachFeatureAtPixel(e.pixel, function (feature) {
            clicked_feature = feature
          })
          showModalForm(clicked_feature.home_id);
      });

      this.map = map;

    },

    set_markers(){
      let features = [],
          center = fromLonLat([86.3035, 54.4191]),
          marker_style_default = this.marker_style_default;

      this.rows.forEach(row => {
        if(row.lat && row.lon){
          let point_coords = fromLonLat([row.lon, row.lat]);
          center = point_coords;
          let iconFeature = new Feature({
              geometry: new Point(point_coords),
          })
          iconFeature.home_id = row.id;
          iconFeature.setStyle(marker_style_default);
          features.push(iconFeature);
        }
      });

      let vectorSource = new VectorSource({
          features: features,
      });

      this.markers_layer.setSource(vectorSource);
      this.map.set('view', new View({
          center: center,
          zoom: 17
        }));
    },
    showModalForm(id) {
      if (id >= 0) {
        this.showModal = true;
        this.modalSource = "/tu/home/" + id + "/";
      }
    },
    hideModalForm() {
      this.showModal = false;
    },
    saveModalForm() {
      this.showModal = false;
      this.$emit("reloadTable");
    },    
    show_table(){
      this.$emit("setPageSize", '');
      this.$router.push({ name: 'houses'})
    },
    show_map() {
      this.$emit("setPageSize", 10000);
      this.$router.push({ name: 'housesMap'})
    },
  },
};
</script>

<style>
  .map {
    height: 100%;
    width: 100%;
  }
</style>