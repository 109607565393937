import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './middleware/auth'
import guest from './middleware/guest'

import Streets from '../pages/streets/Page.vue'
import StreetsTable from '../pages/streets/Table.vue'

import House from '../pages/houses/Page.vue'
import HouseTable from '../pages/houses/Table.vue'
import HouseMap from '../pages/houses/HouseMap.vue'

import Ulichkom from '../pages/ulichkoms/Page.vue'
import UlichkomTable from '../pages/ulichkoms/Table.vue'

import Uik from '../pages/uik/Page.vue'
import UikTable from '../pages/uik/Table.vue'


import Report from '../pages/reports/Page.vue'

import LoginPage from '../pages/login/Page.vue'
import Login from '../pages/login/Login.vue'
import LoginJWT from '../pages/login/LoginJWT.vue'
import ChangePass from '../pages/login/ChangePass.vue'
import RecoveryPass from '../pages/login/RecoveryPass.vue'
import Page404 from '../pages/404.vue'

Vue.use(VueRouter)


const routes = [
  { path: '', redirect: '/streets' },
  {
    path: '/login',
    component: LoginPage,
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          middleware: guest
        },
      },
      {
        path: 'jwt/:token',
        name: 'loginJWT',
        component: LoginJWT,
      },
      {
        path: 'recovery-pass',
        name: 'recovery-pass',
        component: RecoveryPass,
      },
      {
        path: 'change-pass',
        name: 'change-pass',
        component: ChangePass,
      },
    ]
  },
  {
    path: '/streets/:id?',
    component: Streets,
    children: [
      {
        path: '',
        name: 'streets',
        component: StreetsTable,
        meta: {
          middleware: auth
        },
      },
    ],
  },
  {
    path: '/houses/:id?',
    component: House,
    children: [
      {
        path: '',
        name: 'houses',
        component: HouseTable,
        meta: {
          middleware: auth
        },
      },
      {
        path: 'map/:id?',
        name: 'housesMap',
        component: HouseMap
      },
    ]
  },
  {
    path: '/ulichkoms/:id?',
    component: Ulichkom,
    children: [
      {
        path: '',
        name: 'ulichkoms',
        component: UlichkomTable,
        meta: {
          middleware: auth
        },
      },
    ],
  },
  {
    path: '/uik/:id?',
    component: Uik,
    children: [
      {
        path: '',
        name: 'uik',
        component: UikTable,
        meta: {
          middleware: auth
        },
      },
    ],
  },
  {
    path: '/reports/:tu?',
    component: Report,
    name: 'reports',
    meta: {
      middleware: auth
    },
  },
  {
    path: '*',
    name: '404',
    component: Page404
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
  }
  return middleware(context)
})

export default router