<template>
  <layout>

    <div class="page-header d-print-none">
      <div class="row align-items-center">
        <div class="col">
          <h2 class="page-title">
            Отчёты
          </h2>
        </div>
        <div class="col-auto ms-auto d-print-none">
          <div class="form-group">
            <div>
              <select class="form-select" v-model="tu_id" @change="select_tu()">
                <option v-for="tu in tu_list" :value="tu.id" :key="tu.id">
                  {{ tu.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-deck row-cards">
      <div class="col-3"><pasport-tu></pasport-tu></div>
      <div class="col-3"><pasport-street></pasport-street></div>
      <div class="col-6"><objects></objects></div>
    </div>


  </layout>
</template>


<script>
import Layout from "../../layouts/Base.vue"
import PasportTu from "./PasportTu.vue"
import PasportStreet from "./PasportStreet.vue"
import Objects from "./Objects.vue"


export default {
  components: {
    Layout, PasportTu, PasportStreet, Objects,
  },
  data: function () {
    return {
      tu_list: [],
      tu_id: 0,
    };
  },
  mounted() {
    this.tu_id = this.$route.params.tu;

    fetch("/tu/list/")
      .then((response) => response.json())
      .then((data) => {
        this.tu_list = data.results;
        if(!this.tu_id){
          this.tu_id = this.tu_list[0].id;
          this.select_tu();
        }
      });
  },
  methods: {
    select_tu: function(){
      if(this.tu_id) {
        this.$router.push({ name: 'reports', params:{tu: this.tu_id}});
        this.$router.go(this.$router.currentRoute);
      }
    }
  }

}

</script>