<template>
  <div class="flex-fill d-flex flex-column justify-content-center py-4">
    <div class="container-tight py-6">
      <form class="card card-md" v-on:submit.prevent="loginSubmit">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Форма изменения пароля</h2>
          <div v-if="!changeOk" class="mb-2">
            <label class="form-label"> Пароль </label>
            <input
              type="password1"
              :class="{ 'is-invalid': formError.password1 }"
              class="form-control"
              placeholder="Password1"
              autocomplete="off"
              v-model.trim="password1"
            />
            <fieldErrors v-bind:errors="formError.password1"></fieldErrors>
          </div>
          <div v-if="!changeOk" class="mb-2">
            <label class="form-label"> Повторите пароль </label>
            <input type="password2" class="form-control" placeholder="Password2" autocomplete="off" v-model.trim="password2" />
          </div>

          <input type="hidden" :class="{ 'is-invalid': formError.non_field_errors }" />
          <fieldErrors v-bind:errors="formError.non_field_errors"></fieldErrors>
          <div class="form-footer">
            <button type="button" v-if="!changeOk" v-on:click="changePass" class="btn btn-primary w-100">Изменить пароль</button>
          </div>
          <div class="form-footer">
            <p v-if="seccessMessage" class="text-success">{{ seccessMessage }}</p>
            <router-link class="btn btn-primary w-100" :to="{ name: 'streets' }">Продолжить</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "ChangePass",
  components: {
    fieldErrors,
  },
  data: () => ({
    password1: "",
    password2: "",
    seccessMessage: "",
    formError: Object,
    changeOk: false,
  }),
  methods: {
    async changePass() {
      const response = await fetch("/auth/change-password/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password1: this.password1,
          password2: this.password2,
        }),
      });
      if (response.ok) {
        this.changeOk = true;
        this.seccessMessage = "Пароль успешно изменён.";
      } else {
        var error = await response.json();
        this.formError = error;
      }
    },
  },
};
</script>

<style>
.inner {
  position: absolute;
  top: 10%;
  border: 1px solid #404040;
}
</style>