<template>
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Поиск</h3>
  </div>
  <div class="card-body">
    <form>
      <div class="form-group mb-3">
        <label class="form-label">Территориальное управление</label>
        <div>
          <select class="form-select" v-model="searchForm.tu">
            <option v-for="t in tu" :value="t.id" :key="t.id">
              {{ t.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Наименование</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.name">
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Уличком</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.ulichkom_fio">
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Покрытие</label>
        <select class="form-select" v-model="searchForm.surface">
          <option v-for="surface in serfaces" :value="surface.id" :key="surface.id">
            {{ surface.name }}
          </option>
        </select>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Комментарий</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.comment">
        </div>
      </div>

      <div class="form-footer">
        <button type="button" class="btn btn-primary" @click="search()">
          <i class="fa fa-search"></i> Найти
        </button>
        <button type="button" class="btn btn-default pull-right" @click="resetForm()">
          Очистить
        </button>
      </div>
    </form>
  </div>
</div>
</template>


<script>
export default {
  name: 'SearchPanel',
  data: function () {
    return {
      searchForm: {},
      serfaces: Array,
      tu: Array,
    };
  },
  mounted() {
    fetch("/tu/serface/list/")
      .then((response) => response.json())
      .then((data) => {
        this.serfaces = data;
      });
    fetch("/tu/list/")
      .then((response) => response.json())
      .then((data) => {
        this.tu = data.results;
      });
  },
  methods: {
    search() {
      this.$emit('searchFormFilter', this.searchForm);
    },
    resetForm(){
      this.searchForm = {};
      this.search();
    }
  }
}
</script>