<template>
  <div class="navbar-expand-md">
    <div class="collapse navbar-collapse" id="navbar-menu">
      <div class="navbar navbar-light">
        <div class="container-fluid">
          <Topmenu></Topmenu>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

  import Topmenu from './Topmenu.vue'

  export default {
    name: 'Navbar',
    props: {
      msg: String
    },
    components: {
      Topmenu
    }
  }
</script>