<template>
  <div>
      <label class="form-check-label mb-2">{{ name }}:</label>
      <div class="form-check form-check-inline" v-for="key, choice in choices" :key="key">
        <input
          class="form-check-input"
          type="radio"
          :value="key"
          :checked="value === key"
          :name="label"
          v-on:input="$emit('input', $event.target.value)"
          :id="label + '-' + key"
        />
        <label class="form-check-label" :for="label + '-' + key">{{ choice }}</label>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: String,
    label: String,
  },
  data: function () {
    return {
      choices: {
        'Все': undefined,
        'Есть': true,
        'Нет': false
      },
    }
  },
};
</script>
