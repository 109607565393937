<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        {{ name }}
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item dropdown d-none d-md-flex me-3">
          <span>{{ ini }}</span>
        </div>

        <div class="nav-item dropdown d-none d-md-flex me-3">
          <a href="#" v-on:click="logout">Выйти</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "Header",
  data: () => ({
    ini: localStorage.getItem("userIni"),
  }),
  props: {
    name: String,
  },
  methods: {
    async logout() {
      const response = await fetch("/auth/logout/", {
        method: "POST",
      });
      if (response.ok) {
        localStorage.removeItem("userIni");
        localStorage.removeItem("authStatus");
        this.$router.push({ name: "login" }).catch(() => {});
      }
    },
  },
};
</script>