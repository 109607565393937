<template>
  <div class="invalid-feedback">
    <div v-for="item in errors" :key="item">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "fieldErrors",
  props: {
    errors: Array,
  },
};
</script>

<style>
</style>