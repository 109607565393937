<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Дом</h5>

          <p @click="$emit('close')">
            <router-link type="button" class="btn-close" :to="{ name: 'houses' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-4">
              <label class="form-label">Улица</label>
              <select :class="{ 'is-invalid': formError.street }" class="form-select" v-model="form.street">
                <option v-for="street in form.street_choices" :value="street.id" :key="street.id">
                  {{ street.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.street"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">№ дома</label>
              <input type="text" :class="{ 'is-invalid': formError.num }" class="form-control" v-model="form.num" />
              <fieldErrors v-bind:errors="formError.num"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Площадь участка, м²</label>
              <input type="number" :class="{ 'is-invalid': formError.square }" class="form-control" v-model="form.square" min="0" step=".01" />
              <fieldErrors v-bind:errors="formError.square"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Жилая площадь, м²</label>
              <input type="number" :class="{ 'is-invalid': formError.live_square }" class="form-control" v-model="form.live_square" min="0" step=".01" />
              <fieldErrors v-bind:errors="formError.live_square"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-3">
              <label class="form-label">Тип</label>
              <select :class="{ 'is-invalid': formError.live_type }" class="form-select" v-model="form.live_type">
                <option v-for="(value, key) in form.livetypes_choices" :value="key" :key="key">
                  {{ value }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.live_type"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Вид использования</label>
              <select :class="{ 'is-invalid': formError.residental_permitted_uses }" class="form-select" v-model="form.residental_permitted_uses" v-if="form.live_type=='residential'">
                <option v-for="(value, key) in form.residental_permitted_uses_choices" :value="key" :key="key">
                  {{ value }}
                </option>
              </select>

              <select :class="{ 'is-invalid': formError.non_residental_permitted_uses }" class="form-select" v-model="form.non_residental_permitted_uses" v-if="form.live_type=='non_residential'">
                <option v-for="(value, key) in form.non_residental_permitted_uses_choices" :value="key" :key="key">
                  {{ value }}
                </option>
              </select>
            </div>
            <div class="col-lg-2" v-if="form.live_type=='residential' && form.residental_permitted_uses=='mkd'">
              <label class="form-label">Подъездов</label>
              <input type="number" :class="{ 'is-invalid': formError.entrance }" class="form-control" v-model="form.entrance" min="0" />
              <fieldErrors v-bind:errors="formError.entrance"></fieldErrors>
            </div>
            <div class="col-lg-2" v-if="form.live_type=='residential' && form.residental_permitted_uses=='mkd'">
              <label class="form-label">Квартир</label>
              <input type="number" :class="{ 'is-invalid': formError.residence }" class="form-control" v-model="form.residence" min="0" />
              <fieldErrors v-bind:errors="formError.residence"></fieldErrors>
            </div>
            <div class="col-lg-2" v-if="form.live_type=='residential' && form.residental_permitted_uses=='mkd'">
              <label class="form-label">Жителей</label>
              <input type="number" :class="{ 'is-invalid': formError.population }" class="form-control" v-model="form.population" min="0" />
              <fieldErrors v-bind:errors="formError.population"></fieldErrors>
            </div>
            <div class="col-lg-4" v-if="form.residental_permitted_uses=='izhs'">
              <label class="form-check" style="margin-top:3em">
                <input type="checkbox" :class="{ 'is-invalid': formError.abandoned }" class="form-check-input" v-model="form.abandoned" />
                <span class="form-check-label">Заброшенное</span>
              </label>
              <fieldErrors v-bind:errors="formError.abandoned"></fieldErrors>
            </div>

          </div>

          <div class="row mb-3">
            <div class="col-lg-12">
              <label class="form-label">Управляющая компания</label>
              <input type="text" :class="{ 'is-invalid': formError.uk }" class="form-control" v-model="form.uk" />
              <fieldErrors v-bind:errors="formError.uk"></fieldErrors>
            </div>

          </div>



          <div class="row mb-3">
            <div class="col-lg-2">
              <label class="form-label">Год</label>
              <input type="number" :class="{ 'is-invalid': formError.build_year }" class="form-control" v-model="form.build_year" min="1800" max="2100" step="1" />
              <fieldErrors v-bind:errors="formError.build_year"></fieldErrors>
              <fieldErrors v-bind:errors="formError.build_year"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Комнат</label>
              <input type="number" :class="{ 'is-invalid': formError.rooms }" class="form-control" v-model="form.rooms" min="0" step="1" />
              <fieldErrors v-bind:errors="formError.rooms"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Этажей</label>
              <input type="number" :class="{ 'is-invalid': formError.floars }" class="form-control" v-model="form.floars" min="0" step="1" />
              <fieldErrors v-bind:errors="formError.floars"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Материал стен</label>
              <input type="text" :class="{ 'is-invalid': formError.wall }" class="form-control" v-model="form.wall" />
              <fieldErrors v-bind:errors="formError.wall"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Материал крыши</label>
              <input type="text" :class="{ 'is-invalid': formError.roof }" class="form-control" v-model="form.roof" />
              <fieldErrors v-bind:errors="formError.roof"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-2">
              <label class="form-check">
                <input type="checkbox" :class="{ 'is-invalid': formError.pump }" class="form-check-input" v-model="form.pump" />
                <span class="form-check-label">Колонка</span>
              </label>
              <fieldErrors v-bind:errors="formError.pump"></fieldErrors>
            </div>
            <div class="col-lg-4">
              <label class="form-check">
                <input type="checkbox" :class="{ 'is-invalid': formError.fire_hydrant }" class="form-check-input" v-model="form.fire_hydrant" />
                <span class="form-check-label">Пожарный гидрант</span>
              </label>
              <fieldErrors v-bind:errors="formError.fire_hydrant"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-check">
                <input type="checkbox" :class="{ 'is-invalid': formError.lighting }" class="form-check-input" v-model="form.lighting" />
                <span class="form-check-label">Освет. приборы</span>
              </label>
              <fieldErrors v-bind:errors="formError.lighting"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-check">
                <input type="checkbox" :class="{ 'is-invalid': formError.childrens_playground }" class="form-check-input" v-model="form.childrens_playground" />
                <span class="form-check-label">Детская площадка</span>
              </label>
              <fieldErrors v-bind:errors="formError.childrens_playground"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-8">
              <label class="form-label">Домовладелец</label>
              <input type="text" :class="{ 'is-invalid': formError.owner }" class="form-control" v-model="form.owner" placeholder="пример: Иванов Иван Иванович" />
              <fieldErrors v-bind:errors="formError.owner"></fieldErrors>
            </div>

            <div class="col-lg-4">
              <label class="form-label">Телефон</label>
              <input type="text" :class="{ 'is-invalid': formError.phone }" class="form-control" v-model="form.phone" />
              <fieldErrors v-bind:errors="formError.phone"></fieldErrors>
            </div>
          </div>


          <div class="mb-3">
            <div class="row">
              <div class="col-lg-8">
                <label class="form-label">
                  Мусорные контейнеры
                  <a class="btn btn-sm icon-btn" @click="addGarbage()"><i class="fa fa-plus"></i></a>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="form-label">Поверхность</label>
              </div>
            </div>

            <div class="row mb-3" v-for="(garbage, index) in form.garbages" :key="garbage.id">
              <div class="col-lg-8">
                <select
                  class="form-select"
                  :class="{ 'is-invalid': formError.garbages && formError.garbages[index].category && formError.garbages[index].category.length }"
                  v-model="garbage.category"
                >
                  <option v-for="choice in form.garbage_categories" :value="choice.category" :key="choice.category">
                    {{ choice.name }}
                  </option>
                </select>
                <fieldErrors v-bind:errors="formError.garbages && formError.garbages[index].category"></fieldErrors>
              </div>
              <div class="col-lg-4">
                <div class="input-group">
                <select
                  class="form-select"
                  :class="{ 'is-invalid': formError.garbages && formError.garbages[index].surface && formError.garbages[index].surface.length }"
                  v-model="garbage.surface"
                >
                  <option v-for="surface in form.surface_choices" :value="surface.id" :key="surface.id">
                    {{ surface.name }}
                  </option>
                </select>
                  <button
                    class="btn"
                    type="button"
                    :class="{ 'is-invalid': formError.garbages && formError.garbages[index].length && formError.garbages[index].surface.length }"
                    @click="removeGarbage(index)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                <fieldErrors v-bind:errors="formError.garbages && formError.garbages[index].surface"></fieldErrors>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-12">
              <div>
                <label class="form-label">Дополнительная информация</label>
                <textarea :class="{ 'is-invalid': formError.comment }" class="form-control" rows="3" v-model="form.comment"></textarea>
                <fieldErrors v-bind:errors="formError.comment"></fieldErrors>
              </div>
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto">
            <a class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>

          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'houses' }"> Закрыть </router-link>
          </p>
          <p>
            <a @click="save()" title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto"> <i class="icon fa fa-save"></i> Сохранить </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Form",

  components: {
    fieldErrors,
  },

  data: function () {
    return {
      form: {},
      formError: {},
    }
  },
  props: {
    source: String,
  },
  mounted() {
    fetch(this.source)
      .then((response) => {
        if (!response.ok) {
          this.$router.push({ name: "houses" })
          this.$emit("close")
          return 0;
        }
        return response.json()
      })
      .then((data) => {
        this.form = data
      });
  },
  methods: {
    save() {
      var this_form = this;
      fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then((result) => {
          if (!result.ok) throw result;
          return result.json()
        })
        .then(function () {
          this_form.$emit("save");
        })
        .catch((error) => {
          error.json().then((error) => {
            this.formError = error
          })
        });
    },
    addGarbage() {
      delete this.formError.garbages;
      this.form.garbages.push({ category: "", surface: "" });
    },
    removeGarbage(index) {
      delete this.formError.garbages;
      this.form.garbages.splice(index, 1);
    },    
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>
