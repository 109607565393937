<template>
  <div class="flex-fill d-flex align-items-center justify-content-center">
    <div class="container-tight py-5">
      <div class="text-center mb-4">
        <div v-if="loginOk" class="card-body">
          <h1 class="card-title text-center mb-4">{{ fio }}</h1>
          <h2 class="card-title text-center mb-4">Вы успешно авторизовались!</h2>
          <div class="form-footer">
            <router-link class="btn btn-primary w-100" :to="{ name: 'streets' }">Продолжить работу с сайтом</router-link>
          </div>
          <div class="form-footer">
            <router-link class="btn btn-primary w-100" :to="{ name: 'change-pass' }">Изменить пароль</router-link>
          </div>
        </div>

        <div v-if="!loginOk">
          <h2 class="card-title text-center mb-4">Проблема авторизации!</h2>
          <div class="isa_error" v-for="error in errors.non_field_errors" :key="error">
            <i class="fa fa-times-circle"></i>
            {{ error }}
          </div>
          <div class="form-footer">
            <router-link class="btn btn-primary" :to="{ name: 'login' }">Авторизация</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "LoginJWT",
  data: () => ({
    fio: "",
    errors: Object,
    loginOk: false,
  }),
  async mounted() {
    const token = this.$route.params.token;
    try {
      const response = await fetch(`/auth/loginJWT/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      });
      if (response.ok) {
        var data = await response.json();
        this.fio = data.fio;
        this.loginOk = true;
        localStorage.setItem("userIni", data.ini);
        localStorage.setItem("authStatus", true);
      } else {
        this.errors = await response.json();
      }
    } catch (responseError) {
      console.error({ "Error!": responseError });
    }
  },
};
</script>

<style>
.inner {
  position: absolute;
  top: 10%;
  border: 1px solid #404040;
}
.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  margin: 10px 0px;
  padding: 12px;
}
.isa_info {
  color: #00529b;
  background-color: #bde5f8;
}
.isa_success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.isa_warning {
  color: #9f6000;
  background-color: #feefb3;
}
.isa_error {
  color: #d8000c;
  background-color: #ffd2d2;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 10px 22px;
  font-size: 2em;
  vertical-align: middle;
}
</style>