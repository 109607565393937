<template>
  <div class="card" v-on:keyup.esc="showModal = false">
    <div class="card-header">
      <h3 class="card-title">Дома</h3>
    </div>
    <ul class="nav nav-tabs" data-bs-toggle="tabs">
      <li class="nav-item">
        <a class="nav-link active" @click="show_table()">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-list"></i>
          </span>
          <span class="nav-link-title"> Список </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="show_map()">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-map-marker"></i>
          </span>
          <span class="nav-link-title"> Карта </span>
        </a>
      </li>
      <li class="nav-item ms-auto right-btns">
        <p
          class="btn btn-primary"
          @click="
            showModal = true;
            modalSource = '/tu/home/new/';
          "
        >
          <i class="fa fa-plus"></i> Добавить
        </p>
        <p class="btn btn-info" @click="$emit('print')">
          <i class="fa fa-print"></i> Печать
        </p>
        <p class="btn btn-info" @click="$emit('saveXLS')">
          <i class="fa fa-file-excel-o"></i> XLS
        </p>
      </li>
    </ul>

    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap datatable table-hover">
        <thead>
          <tr>
            <th width="200">Улица</th>
            <th width="50">№ дома</th>
            <th>Домовладелец</th>
            <th class="text-center">Колонка</th>
            <th class="text-center">ПГ</th>
            <th class="text-center">Дет. площ</th>
            <th class="text-center">Освет. приборы</th>
            <th class="text-center">Мус. конт.</th>
            <th class="text-center">Мус. бунк.</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            v-bind:key="row.id"
            @click="
              showModal = true;
              modalSource = '/tu/home/' + row.id + '/';
              $router.push({ name: 'houses', params: { id: row.id } }).catch(() => {});
            "
          >
            <td @click="showModalForm">
              <router-link :to="{ name: 'houses', params: { id: row.id } }"> {{ row.street_name }}</router-link>
            </td>
            <td class="text-end">
              {{ row.num }}
            </td>
            <td>{{ row.owner }}</td>
            <td class="text-center"><i class="fa" :class="{ 'fa-check': row.pump, 'fa-times': !row.pump }"></i></td>
            <td class="text-center"><i class="fa" :class="{ 'fa-check': row.fire_hydrant, 'fa-times': !row.fire_hydrant }"></i></td>
            <td class="text-center"><i class="fa" :class="{ 'fa-check': row.childrens_playground, 'fa-times': !row.childrens_playground }"></i></td>
            <td class="text-center"><i class="fa" :class="{ 'fa-check': row.lighting, 'fa-times': !row.lighting }"></i></td>
            <td class="text-center">{{ row.container_cnt }}</td>
            <td class="text-center">{{ row.bunker_cnt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paginator :paginator="paginator"></Paginator>
    <Form v-if="showModal" @close="hideModalForm" @save="saveModalForm" :source="modalSource"></Form>
  </div>
</template>


<script>
import Form from "./Form.vue";
import Paginator from "../../components/Paginator.vue";

export default {
  name: "DataTable",
  data: function () {
    return {
      showModal: false,
      modalSource: "",
    };
  },
  props: {
    rows: Array,
    paginator: Object,
  },
  components: {
    Form,
    Paginator,
  },
  mounted() {
    this.showModalForm();
  },
  methods: {
    showModalForm() {
      var id = this.$route.params.id;
      if (id >= 0) {
        this.showModal = true;
        this.modalSource = "/tu/home/" + id + "/";
      }
    },
    hideModalForm() {
      this.showModal = false;
      this.$emit("reloadTable");
    },
    saveModalForm() {
      this.showModal = false;
      this.$emit("reloadTable");
    },
    show_table(){
      this.$emit("setPageSize", '');
      this.$router.push({ name: 'houses'})
    },
    show_map() {
      this.$emit("setPageSize", 10000);
      this.$router.push({ name: 'housesMap'})
    },    
  },
};
</script>
