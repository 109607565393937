<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Председатель уличного комитета</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'ulichkoms' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">ФИО</label>
              <input type="text" :class="{ 'is-invalid': formError.fio }" class="form-control" v-model="form.fio" />
              <fieldErrors v-bind:errors="formError.fio"></fieldErrors>
            </div>
            <div class="col-lg-6">
              <label class="form-label">Телефон</label>
              <input type="text" :class="{ 'is-invalid': formError.phone }" class="form-control" v-model="form.phone" />
              <fieldErrors v-bind:errors="formError.phone"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">Адрес</label>
              <input type="text" :class="{ 'is-invalid': formError.address }" class="form-control" v-model="form.address" />
              <fieldErrors v-bind:errors="formError.address"></fieldErrors>
            </div>
            <div class="col-lg-6">
              <label class="form-label">Улица</label>
              <span v-for="street in form.streets" :key="street.id" style="line-height: 28px;">
                &raquo;
                <router-link :to="{ name: 'streets', params: { id: street.id }}" style="margin-right: 1em;">{{ street.name }}</router-link>
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-12">
              <label class="form-label">Дополнительная информация</label>
              <textarea :class="{ 'is-invalid': formError.comment }" class="form-control" rows="3" v-model="form.comment"></textarea>
              <fieldErrors v-bind:errors="formError.comment"></fieldErrors>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'ulichkoms' }"> Закрыть </router-link>
          </p>
          <p @click="save()">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'ulichkoms' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Form",

  components: {
    fieldErrors,
  },

  data: function () {
    return {
      form: {},
      formError: {},
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source);
    if (response.ok) {
      this.form = await response.json();
    } else {
      this.$router.push({ name: "ulichkoms" });
      this.$emit("close");
    }
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("save");
      } else {
        this.formError = await response.json();
      }
    },
  },
};
</script>
