<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Паспорт территории</h3>
    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter table-hover text-nowrap datatable">
        <tbody>
          <tr>
            <td>Улиц</td>
            <td class="text-center"><a href="#">{{ report.streets }}</a></td>
          </tr>
          <tr>
            <td>Километраж</td>
            <td class="text-center">{{ report.length }} км</td>
          </tr>
          <tr>
            <td>Домовладений</td>
            <td class="text-center"><a href="#">{{ report.homes }}</a></td>
          </tr>
          <tr>
            <td>Жителей</td>
            <td class="text-center">{{ report.population }}</td>
          </tr>
          <tr>
            <td>Колонок</td>
            <td class="text-center"><a href="#">{{ report.pump }}</a></td>
          </tr>
          <tr>
            <td>Пожарных гидрантов</td>
            <td class="text-center"><a href="#">{{ report.fire_hydrant }}</a></td>
          </tr>
          <tr>
            <td>Мусорных контейнеров</td>
            <td class="text-center"><a href="#">{{ report.container }}</a></td>
          </tr>
          <tr>
            <td>Мусорных бункеров</td>
            <td class="text-center"><a href="#">{{ report.bunker }}</a></td>
          </tr>
          <tr>
            <td>Осветительных приборов</td>
            <td class="text-center"><a href="#">{{ report.lighting }}</a></td>
          </tr>
          <tr>
            <td>Детских площадок</td>
            <td class="text-center"><a href="#">{{ report.childrens_playground }}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>

export default {
  data: function () {
    return {
      report: {},
    };
  },
  methods: {
    getReport() {
      var tu = this.$route.params.tu;
      fetch("/tu/report/" + tu + "/pasport/tu/")
        .then((response) => response.json())
        .then((data) => {
          this.report = data;
        });
    },
  },
  mounted() {
    this.getReport();
  },

}

</script>
