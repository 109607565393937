<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Улица</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'streets' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">Наименование</label>
              <input type="text" :class="{ 'is-invalid': formError.name }" class="form-control" placeholder="пример: Строителей улица" v-model="form.name" />
              <fieldErrors v-bind:errors="formError.name"></fieldErrors>
            </div>
            <div class="col-lg-2">
              <label class="form-label">Жителей</label>
              <input type="number" class="form-control" placeholder="" min="0" step="1" :class="{ 'is-invalid': formError.population }" v-model="form.population" />
              <fieldErrors v-bind:errors="formError.population"></fieldErrors>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Управление</label>
              <select :class="{ 'is-invalid': formError.tu }" class="form-select" v-model="form.tu">
                <option v-for="tu in form.tu_choices" :value="tu.id" :key="tu.id">
                  {{ tu.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.tu"></fieldErrors>
            </div>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-lg-8">
                <label class="form-label">
                  Покрытие
                  <a class="btn btn-sm icon-btn" @click="addStreetSurface()"><i class="fa fa-plus"></i></a>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="form-label">Протяжённость, км</label>
              </div>
            </div>

            <div class="row mb-3" v-for="(surface, index) in form.surfaces" :key="surface.id">
              <div class="col-lg-8">
                <select
                  class="form-select"
                  :class="{ 'is-invalid': formError.surfaces && formError.surfaces[index].surface && formError.surfaces[index].surface.length }"
                  v-model="surface.surface"
                >
                  <option v-for="surface in form.surface_choices" :value="surface.id" :key="surface.id">
                    {{ surface.name }}
                  </option>
                </select>
                <fieldErrors v-bind:errors="formError.surfaces && formError.surfaces[index].surface"></fieldErrors>
              </div>
              <div class="col-lg-4">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    min="0"
                    step=".01"
                    :class="{ 'is-invalid': formError.surfaces && formError.surfaces[index].length && formError.surfaces[index].length.length }"
                    v-model="surface.length"
                  />
                  <button
                    class="btn"
                    type="button"
                    :class="{ 'is-invalid': formError.surfaces && formError.surfaces[index].length && formError.surfaces[index].length.length }"
                    @click="removeStreetSurface(index)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <fieldErrors v-bind:errors="formError.surfaces && formError.surfaces[index].length"></fieldErrors>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-lg-8">
                <label class="form-label">
                  Председатель уличкома
                  <a class="btn btn-sm icon-btn" @click="addUlichkom()"><i class="fa fa-plus"></i></a>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="form-label">Телефон</label>
              </div>
            </div>

            <div class="row mb-3" v-for="(ulichkom, index) in form.ulichkoms" :key="ulichkom.id">
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="ФИО"
                  :class="{ 'is-invalid': formError.ulichkoms && formError.ulichkoms[index].fio && formError.ulichkoms[index].fio.length }"
                  v-model="ulichkom.fio"
                />
                <fieldErrors v-bind:errors="formError.ulichkoms && formError.ulichkoms[index].fio"></fieldErrors>
              </div>
              <div class="col-lg-4">
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder=""
                    :class="{ 'is-invalid': formError.ulichkoms && formError.ulichkoms[index].phone && formError.ulichkoms[index].phone.length }"
                    v-model="ulichkom.phone"
                  />
                  <button
                    class="btn"
                    type="button"
                    :class="{ 'is-invalid': formError.ulichkoms && formError.ulichkoms[index].phone && formError.ulichkoms[index].phone.length }"
                    @click="removeUlichkom(index)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <fieldErrors v-bind:errors="formError.ulichkoms && formError.ulichkoms[index].phone"></fieldErrors>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-12">
              <label class="form-label">Дополнительная информация</label>
              <textarea :class="{ 'is-invalid': formError.comment }" class="form-control" rows="3" v-model="form.comment"></textarea>
              <fieldErrors v-bind:errors="formError.comment"></fieldErrors>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto">
            <a class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>

          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'streets' }"> Закрыть </router-link>
          </p>
          <p @click="save()">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'streets' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Form",

  components: {
    fieldErrors,
  },

  data: function () {
    return {
      form: {},
      formError: {},
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source);
    if (response.ok) {
      this.form = await response.json();
    } else {
      this.$router.push({ name: "streets" });
      this.$emit("close");
    }
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("save");
      } else {
        this.formError = await response.json();
        console.log(this.formError);
      }
    },
    addStreetSurface() {
      delete this.formError.surfaces;
      this.form.surfaces.push({ surface: "", length: "" });
    },
    removeStreetSurface(index) {
      delete this.formError.surfaces;
      this.form.surfaces.splice(index, 1);
    },
    addUlichkom() {
      delete this.formError.ulichkoms;
      this.form.ulichkoms.push({ fio: "", phone: "" });
    },
    removeUlichkom(index) {
      delete this.formError.ulichkoms;
      this.form.ulichkoms.splice(index, 1);
    },
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>
