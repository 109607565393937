<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Объекты</h3>
      <div class="card-actions">
          <div class="form-group">
              <select class="form-select" v-model="object_type" @change="showObjects()">
                <option value="fire_hydrant">Пожарные гидранты</option>
                <option value="childrens_playground">Детские площадки</option>
                <option value="lighting">Осветительные приборы</option>
                <option value="garbage_container">Мусорные контейнеры</option>
                <option value="garbage_bunker">Мусорные бункеры</option>
              </select>
          </div>
      </div>

    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter table-hover text-nowrap datatable">
        <thead>
          <tr>
            <th>Улица</th>
            <th class="text-center">Кол-во</th>
            <th style="width: 100%;">№№ домов</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="street in streets" :key="street.id">
            <td>{{ street.name }}</td>
            <td class="text-center">{{ street.homes.length }}</td>
            <td>{{ street.homes.join(', ') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>

export default {
  data: function () {
    return {
      streets: [],
      object_type: 'fire_hydrant',
    };
  },
  methods: {
    showObjects:function(){
      var tu = this.$route.params.tu;
      fetch("/tu/report/" + tu + "/objects/" + this.object_type + "/")
        .then((response) => response.json())
        .then((data) => {
          this.streets = data
        });
    }
  },
  mounted() {
    this.showObjects();
  },

}

</script>
