<template>
  <router-view></router-view>
</template>


<script>
export default {
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.inner {
  position: absolute;
  top: 10%;
  border: 1px solid #404040;
}
</style>