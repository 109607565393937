<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Поиск</h3>
    </div>
    <div class="card-body">
      <form>
      <div class="form-group mb-3">
        <label class="form-label">Территориальное управление</label>
        <div>
          <select class="form-select" v-model="searchForm.tu" @change="update_streets_list()">
            <option v-for="t in tu" :value="t.id" :key="t.id">
              {{ t.name }}
            </option>
          </select>
        </div>
      </div>
        <div class="form-group mb-3">
          <label class="form-label">Улица</label>
          <div>
            <select class="form-select" v-model="searchForm.street">
              <option v-for="street in streets" :value="street.id" :key="street.id">
                {{ street.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Дом</label>
          <input type="text" class="form-control" v-model="searchForm.num" />
          <small class="form-hint">например: "13" или "13 к.2"</small>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Управляющая компания</label>
          <div>
            <input type="text" class="form-control" v-model="searchForm.uk" />
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Домовладелец</label>
          <div>
            <input type="text" class="form-control" v-model="searchForm.owner" />
          </div>
        </div>
        <div class="form-group">
          <div>
            <CheckboxAsRadio name="Колонка" v-model="searchForm.pump" label="pump"></CheckboxAsRadio>
            <CheckboxAsRadio name="Пожарный гидрант" v-model="searchForm.fire_hydrant" label="fire_hydrant"></CheckboxAsRadio>
            <CheckboxAsRadio name="Детская площадка" v-model="searchForm.childrens_playground" label="childrens_playground"></CheckboxAsRadio>
            <CheckboxAsRadio name="Осветительные приборы" v-model="searchForm.lighting" label="lighting"></CheckboxAsRadio>
            <CheckboxAsRadio name="Мусорные контейнеры" v-model="searchForm.garbage_container" label="garbage_container"></CheckboxAsRadio>
            <CheckboxAsRadio name="Мусорные бункеры" v-model="searchForm.garbage_bunker" label="garbage_bunker"></CheckboxAsRadio>
          </div>
        </div>
        <div class="form-footer">
          <button type="button" class="btn btn-primary" @click="search()"><i class="fa fa-search"></i> Найти</button>
          <button type="button" class="btn btn-default pull-right" @click="resetForm()">Очистить</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>

import CheckboxAsRadio from "../../components/CheckboxAsRadio.vue";

export default {
  name: "SearchPanel",
  data: function () {
    return {
      searchForm: Object,
      streets: Object,
      tu: Array,
    };
  },
  components: {
    CheckboxAsRadio,
  },
  mounted() {
    this.update_streets_list()
    fetch("/tu/list/")
      .then((response) => response.json())
      .then((data) => {
        this.tu = data.results;
      });
  },
  methods: {
    update_streets_list(){
      let tu = this.searchForm.tu||'';
      fetch("/tu/street/search-list/?tu=" + tu)
        .then((response) => response.json())
        .then((data) => {
          this.streets = data;
        });
      },
    search() {
      this.$emit("searchFormFilter", this.searchForm);
    },
    resetForm() {
      this.searchForm = {
        street: [],
      };
      this.search();
    },
  },
};
</script>