<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Поиск</h3>
    </div>
    <div class="card-body">
      <form>
      <div class="form-group mb-3">
        <label class="form-label">Территориальное управление</label>
        <div>
          <select class="form-select" v-model="searchForm.tu" @change="update_streets_list()">
            <option v-for="t in tu" :value="t.id" :key="t.id">
              {{ t.name }}
            </option>
          </select>
        </div>
      </div>
        <div class="form-group mb-3">
          <label class="form-label">УИК</label>
          <input type="text" class="form-control" v-model="searchForm.num" />
        </div>

        <div class="form-group mb-3">
          <label class="form-label">Улица</label>
          <div>
            <select class="form-select" v-model="searchForm.street">
              <option v-for="street in streets" :value="street.id" :key="street.id">
                {{ street.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" v-model="searchForm.sixty_plus">
            <span class="form-check-label">60+</span>
          </label>
        </div>

        <div class="form-group mb-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" v-model="searchForm.eighty_plus">
            <span class="form-check-label">80+</span>
          </label>
        </div>
        <div class="form-group mb-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" v-model="searchForm.large_families">
            <span class="form-check-label">Многодетные</span>
          </label>
        </div>
        <div class="form-group mb-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" v-model="searchForm.disableds">
            <span class="form-check-label">Инвалиды</span>
          </label>
        </div>
        <div class="form-group mb-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" v-model="searchForm.first_time">
            <span class="form-check-label">Впервые голосующие</span>
          </label>
        </div>

        <div class="form-footer">
          <button type="button" class="btn btn-primary" @click="search()"><i class="fa fa-search"></i> Найти</button>
          <button type="button" class="btn btn-default pull-right" @click="resetForm()">Очистить</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>

export default {
  name: "SearchPanel",
  data: function () {
    return {
      searchForm: Object,
      streets: Object,
      tu: Array,
    };
  },

  mounted() {
    this.update_streets_list()
    fetch("/tu/list/")
      .then((response) => response.json())
      .then((data) => {
        this.tu = data.results;
      });
  },
  methods: {
    update_streets_list(){
      fetch("/tu/street/search-list/")
        .then((response) => response.json())
        .then((data) => {
          this.streets = data;
        });
      },
    search() {
      this.$emit("searchFormFilter", this.searchForm);
    },
    resetForm() {
      this.searchForm = {
        sixty_plus: false,
        eighty_plus: false,
        large_families: false,
        disableds: false,
        first_time: false,
      };
      this.search();
    },
  },
};
</script>